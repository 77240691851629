<template>
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label :for="'add-vaccin'+index">Vaccin {{ index }}</label>
        <select
          v-select="{placeholder: 'Selectionnez le vaccin'}"
          v-model="vaccin"
          class="form-control"
          :name="'add-vaccin'+index"
          :id="'add-vaccin'+index"
        >
          <option value="" />
          <option
            v-for="(v,i) in filterdVaccins"
            :value="v.uid"
            :key="i"
          >
            {{ v.libelle }} ({{ v.valence }})
          </option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="dateVaccination">Date Vaccination</label>
        <datepicker
          id="dateVaccination"
          name="dateVaccination"
          input-class="form-control required"
          :language="dateLang"
          :disabled-dates="disabledVaccinationDate"
          format="dd/MM/yyyy"
          placeholder="dd/mm/yyyy"
          v-model="dateVaccination"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label for="dateRapel">Date Rappel</label>
        <datepicker
          id="dateRapel"
          name=""
          input-class="form-control required"
          :language="dateLang"
          :disabled-dates="disabledRappelVaccinationDate"
          format="dd/MM/yyyy"
          placeholder="dd/mm/yyyy"
          v-model="dateRapel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import {fr} from 'vuejs-datepicker/dist/locale/index'
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {index: {type: Number, required: true}},
    components: {Datepicker},
    data(){
        return {
            maladie: null,
            vaccin: null,
            dateRapel: null,
            dateVaccination: new Date(),
            filterdVaccins: []
        }
    },
    mounted(){this.especeVaccinFilter()},
    watch: {
      selectedAnimal(){
        if(this.selectedAnimal !== null) this.especeVaccinFilter()
      },
      vaccin(){
            if(this.vaccin !== null && this.selectedAnimal !== null){
                let v =  this.filterdVaccins.find(item => item.uid === this.vaccin)
                this.maladie = v.maladie
            }else this.maladie = null
        },
        updateData:{
            handler(){
                this.removeMedoc(this.updateData.order)
                this.addMedoc(this.updateData)
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            addMedoc: 'sanitaire/ADD_TRAITEMENT_MEDOC',
            removeMedoc: 'sanitaire/REMOVE_TRAITEMENT_MEDOC'
        }),
        especeVaccinFilter(){
            let animal = this.animaux.find(item=> item.uid === this.selectedAnimal)
            let espece = this.especes.find(item => item.uid === animal.espece)
            this.filterdVaccins = [...this.vaccins].filter(item => item.espece === espece.uid)
        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/especes',
            animaux: 'identification/animaux',
            selectedAnimal: 'identification/selectedAnimal',
            maladies: 'sanitaire/maladies',
            vaccins: 'sanitaire/vaccins',
        }),
        dateLang () {
          return fr
        },
        disabledVaccinationDate () {
          let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
          if(animal !== null && animal !== undefined){
            return {
              to: new Date(animal.dateNaissance),
              from: new Date()
            }
          }
          return {
            to: new Date(),
            from: new Date()
          }
        },

        disabledRappelVaccinationDate () {
          return {
            to: this.dateVaccination
          }
        },
        updateData(){
            return {order: this.index, maladie: this.maladie, vaccin: this.vaccin, dateVaccination: this.dateVaccination, dateRappel: this.dateRapel}
        }
    }
}
</script>

<style>

</style>